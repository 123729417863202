const modal = document.getElementById('newsletterModal');
// Get the button that opens the modal
const btn = document.getElementById('close');

// Get the <span> element that closes the modal
const span = document.getElementsByClassName('modal-overlay')[0];

// When the user clicks the button, open the modal
if (btn !== null) {
  btn.onclick = function () {
    modal.style.display = 'none';
  };
}

window.onload = function () {
  if (!modal) {
    return;
  }

  if (localStorage.getItem('newsletter-modal') === null) {
    modal.style.display = 'block';
    localStorage.setItem('newsletter-modal', true);
  } else {
    modal.style.display = 'none';
  }
};

if (span) {
  // When the user clicks on <span> (close), close the modal
  span.onclick = function () {
    modal.style.display = 'none';
  };
}
